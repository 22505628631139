import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VCalendar from "v-calendar";
import LoadScript from "vue-plugin-load-script";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "v-tooltip/dist/v-tooltip.css";
import "./assets/css/tailwind.css";
import SmartTable from "vuejs-smart-table";
import ApiService from "@/common/api.service";
import VTooltip from "v-tooltip";
import * as Sentry from "@sentry/vue";

ApiService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  store.dispatch("addPageLoading");
  if (to.meta.requiresAuth) {
    Promise.all([store.dispatch("checkAuth")]).then(next());
  } else {
    next();
  }
});

router.afterEach(() => {
  setTimeout(() => store.dispatch("pageLoadingComplete"), 100);
});

const app = createApp(App);

app.use(VTooltip);

app.config.globalProperties.$filters = {
  arrayCommaString(array) {
    if (Array.isArray(array)) {
      if (array.length == 0) return "";
      return array.join(", ");
    }
  },
};

const options = {
  // You can set your default options here
  position: POSITION.TOP_CENTER,
};

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/reports.playda\.io/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(Toast, options);
app.use(store);
app.use(LoadScript);
app.use(SmartTable);
app.use(router);
app.use(VCalendar);
app.mount("#app");
